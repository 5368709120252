import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 


<div className="single-testimonials-item">
                        <p>Percorso assists in the advanced preparation of students to offer meaningful positive and critical comments to their peers. This makes for better class discussion and a greater depth of instructor feedback.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Rev. George Smiga</h3>
                                    <span>Chair, of Preaching Department, Saint Mary Seminary </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Percorso has enriched lay ecclesial ministry formation by providing an integrated communication tool to assist candidates to become "agents" of their formation, fostering habits of life-long learning and spiritual growth, based on self-reflection, setting goals, and dialogue with mentors and peers. Awesome app!</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Patricia Batchman</h3>
                                    <span>Lay Ecclesial Ministry, Former Director</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Percorso made an immediate positive impact on our Seminary institution through more engaged faculty and better prepared students.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Rev. Andrew Turner</h3>
                                    <span>Director of Field Education, Saint Mary Seminary</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Percorso has become a valuable instrument for the coaching and evaluation of student preaching.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Rev. Tom Dragga</h3>
                                    <span>Director of Ongoing Formation, Diocese of Cleveland</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}

                <div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i> 
                        Read More <span></span>
                    </Link>
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;