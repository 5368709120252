import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Plan Your Path. Find Your Way.</h2>
                    <p>Collaborate on goals.  Manage performance.  Stay  closely connected to your students.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-devices" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="13" y="8" width="8" height="12" rx="1" />
                            <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
                            <line x1="16" y1="9" x2="18" y2="9" />
                            </svg>
                            </div>
                            <h3>
                                Percorso Portfolio
                            </h3>
                            
                            <p>Portfolio provides a distance learning platform that keeps field education activities aligned with educational goals and motivated by performance in an easy-to-manage process. </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="3" y="5" width="18" height="14" rx="4" />
                            <path d="M10 9l5 3l-5 3z" />
                            </svg>
                            </div>

                            <h3>
                                Percorso Preaching
                            </h3>

                            <p>Preaching allows preachers, lectors, and rhetoricians to record their presentations and share some or all of them for feedback and critique.</p>
                            
                        </div>
                    </div>

                    
                </div>
            </div>
            <div className="testimonials-view-btn text-center">
                <Link to="/solutions" className="default-btn">
                    <i className="flaticon-view"></i> 
                   Read More <span></span>
                </Link>
            </div>
        </section>
    )
}

export default OurSolutions;